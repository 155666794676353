import * as React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";

import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../../server/models/account";

type Props = {
	title: "Test it コピーテスト" | "Test it コンセプトテスト" | "Test it";
	account: AccountWithoutPassword;
};

export const LogoWithDropdown = React.memo((props: Props) => {
	const { title, account } = props;

	return (
		<Navbar.Brand className="fw-bold">
			<NavDropdown title={title}>
				{account.enableConcept && (
					<NavDropdown.Item href={clientEndpoint.activity}>1.コンセプトテスト</NavDropdown.Item>
				)}
				{account.enableAdCopy && (
					<NavDropdown.Item href={adCopyClientEndpoint.activity}>2.コピーテスト</NavDropdown.Item>
				)}
			</NavDropdown>
		</Navbar.Brand>
	);
});
