import { AccountWithoutPassword } from "../../../server/models/account";
import { Brand } from "../../../server/models/brand";

/**
 * - enableBrands の指定なしはbrandsをそのまま返す
 */
export function brand2BrandOptions(
	brands: Brand[],
	enableBrands?: AccountWithoutPassword["enableConceptBrands"] | AccountWithoutPassword["enableAdCopyBrands"],
) {
	return brands
		.filter((brand) => {
			if (enableBrands === undefined) return true;
			return enableBrands.some(({ order }) => order === brand.order);
		})
		.map((brand) => {
			return { value: brand._id?.toString() ?? "", label: brand.name };
		});
}
