import * as React from "react";
import { Card } from "react-bootstrap";

import type { AccountWithoutPassword } from "../../../../../server/models/account";
import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";
import { ButtonLink } from "../../parts/button-link";
import { evaluateUseAdCopyBrandsFilter } from "../../../../../server/lib/ad-copy/brand";
import { InTrialMessage } from "../../parts/ad-copy/in-trial-message";

type Props = {
	account: AccountWithoutPassword;
};

export const TopPage = ({ account }: Props) => {
	return (
		<Card className="border-0 mx-auto my-5 py-5" style={{ maxWidth: "540px" }}>
			{account.enableConcept && (
				<ButtonLink size="lg" to={clientEndpoint.activity} variant="outline-secondary">
					1.コンセプトテスト
				</ButtonLink>
			)}

			{account.enableAdCopy && (
				<>
					<ButtonLink className="mt-4" size="lg" to={adCopyClientEndpoint.activity} variant="outline-secondary">
						2.コピーテスト
					</ButtonLink>
					{/** 全BHに展開となるが、機能反映と画面上への反映がずれるためenvでコントロールする */}
					{evaluateUseAdCopyBrandsFilter() && <InTrialMessage account={account}></InTrialMessage>}
				</>
			)}
		</Card>
	);
};
