import { ObjectId } from "mongodb";
import { Brand } from "./brand";

export enum AccountRole {
	"admin" = "システム管理者",
	"operation-manager" = "運用管理者",
	"viewer1" = "特権閲覧者",
	"approver" = "承認者",
	"applicant" = "申請者",
	"viewer2" = "閲覧者",
}
export const AccountOptions = Object.entries(AccountRole)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type Account = {
	_id?: string | ObjectId;
	code: string;
	name: string;
	email: string;
	isActive: boolean;
	password: string;
	role: keyof typeof AccountRole;
	brand: (ObjectId | string)[];
};

/**
 * -- warning --
 * - 後日削除予定
 *   - brands: 認証で利用するアカウント情報だけはbrandsを持つ
 *   - viewAdCopyBrands: frontでセットされる。型定義の参照のため
 *   - availableAdCopyBrands: トライアル期間中にコピーテストで利用可能なブランド
 *
 * -- warning --
 * - 削除しない予定 (login時にセットされる)
 *   - enableConceptBrands: コンセプトテストが利用できるブランド
 *   - enableAdCopyBrands: コピーテストが利用できるブランド
 *   - enableConcept: コンセプトテストの利用可否 * enableConceptBrandsで判定、運用管理者以上はブランドに関わらずtrue
 *   - enableAdCopy: コピーテストの利用可否 * enableAdCopyBrandsで判定、運用管理者以上はブランドに関わらずtrue
 */
export type AccountWithoutPassword = Omit<Account, "password"> & {
	brands?: Brand[];
	viewAdCopyBrands?: Brand[];
	availableAdCopyBrands?: Omit<Brand, "brandRecognition">[];
	enableConceptBrands?: Omit<Brand, "brandRecognition">[];
	enableAdCopyBrands?: Omit<Brand, "brandRecognition">[];
	enableConcept?: boolean;
	enableAdCopy?: boolean;
};
